import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      nseKey: "123.45",
      text: "center",
      priceItems: ["L", "MKT", "SL", "SL-M"],
      productItems: ["MIS", "NRML"],
      // showValidityItems: ['DAY', 'IOC'],
      // validityType: "",
      showMore: false,
      coverOrBracketValidityItems: ["DAY"],
      coverOrBracketProductItems: ["MIS"],

      //input field hide show
      isFormSubmit: false,
      requestData: "",
    };
  },

  components: {
    draggable: () =>
      import(/* webpackChunkName: "draggable" */ "../views/draggable.vue"),
  },

  computed: {
    ...mapState("ow", [
      "showOrderWindow",
      "orderType",
      "toggleOrder",
      "minlot",
      "ltp",
      "tickPrice",
      "nseBseType",
      "currentExchange",
      "currentPage",
      "modifyOrder",
      "loading",
      "orderTypeList",
      "validityItems",
      "bracketPriceItems",
      "coverPriceItems",
      "removeCss",
      "isPrice",
      "isTrgPrice",
      "isDisclosedQty",
      "nseSwitchPrice",
      "bseSwitchPrice",
      "currentToken",
      "marginLoader",

      "contractOrderData",
      "selectItem",
      "formetScripName",
      "isBasket",
      "isSsobasket",
      "selectedData",
      "brokerageData",
      "brokerageLoader",
      "freezeQty",
      "isNudge",
      "nudgeMessage",
    ]),
    ...mapGetters("ow", [
      "getPriceType",
      "getProdType",
      "getOrderTab",
      "getValidityType",
      "getTriggerPrice",
      "getCurrentLtp",
      "getAvailableMargin",
      "getOrderTabValue",
      "getCurrentToken",
    ]),
    //...mapGetters("authentication", ["getUserId"]),
    ...mapGetters("basket", ["getBasketScrips"]),

    showproductItems: {
      get() {
        return this.$store.state.ow.showproductItems;
      },
      set(newValue) {
        this.$store.commit("ow/setProductItems", newValue);
      },
    },

    showPriceItems: {
      get() {
        return this.$store.state.ow.showPriceItems;
      },
      set(newValue) {
        this.$store.commit("ow/setPriceItems", newValue);
      },
    },

    quantity: {
      get() {
        return this.$store.state.ow.quantity;
      },
      set(value) {
        this.$store.commit("ow/setCurrentQty", value);
      },
    },

    price: {
      get() {
        // console.log(this.$store.state.ow.price, "isprc");
        if (this.isPrice) {
          return this.$store.state.ow.price &&
            this.$store.state.ow.price != 0 &&
            this.$store.state.ow.price != "0"
            ? this.$store.state.ow.price
            : "";
        } else {
          return 0;
        }
      },
      set(value) {
        this.$store.commit("ow/setCurrentPrice", value);
      },
    },

    triggerPrice: {
      get() {
        return this.isTrgPrice ? this.$store.state.ow.triggerPrice : 0;
      },
      set(value) {
        this.$store.commit("ow/setCurrentTrgPrice", value);
      },
    },

    disclosedQuantity: {
      get() {
        return this.isDisclosedQty ? this.$store.state.ow.disclosedQuantity : 0;
      },
      set(value) {
        this.$store.commit("ow/setDisclosedQty", value);
      },
    },

    nseBseRadioSwitch: {
      get() {
        return this.currentExchange;
      },
      set(value) {
        return value;
      },
    },

    buySellRadio: {
      get() {
        return this.orderType == "buy" ? true : false;
      },
      set(value) {
        return value;
      },
    },

    priceType: {
      get() {
        return this.getPriceType;
      },
      set(value) {
        this.$store.commit("ow/setPriceType", value);
        this.$store.commit("ow/changeOrderType", this.getOrderTabValue);
      },
    },

    prodType: {
      get() {
        return this.getProdType;
      },
      set(value) {
        this.$store.commit("ow/setProdType", value);
      },
    },

    orderTab: {
      get() {
        return this.getOrderTab;
      },
      set(value) {
        this.$store.commit("ow/setOrderTab", value);
      },
    },

    validityType: {
      get() {
        return this.getValidityType;
      },
      set(value) {
        this.$store.commit("ow/setValidityType", value);
      },
    },

    showValidityItems: {
      get() {
        return this.validityItems;
      },
      set(value) {
        this.$store.commit("ow/setValidityItems", value);
      },
    },
    trailStopLossPrice: {
      get() {
        return this.$store.state.ow.trailStopLossPrice;
      },
      set(value) {
        this.$store.commit("ow/setTrailStopLossPrice", value);
      },
    },
    targetPrice: {
      get() {
        return this.$store.state.ow.targetPrice;
      },
      set(value) {
        this.$store.commit("ow/setTargetPrice", value);
      },
    },
    stopLossPrice: {
      get() {
        return this.$store.state.ow.stopLossPrice;
      },
      set(value) {
        this.$store.commit("ow/setStopLossPrice", value);
      },
    },
    nudgeDialog: {
      get() {
        return this.$store.state.ow.nudgeDialog;
      },
      set(value) {
        this.$store.commit("ow/setNudgeDialog", value);
      },
    },
  },

  methods: {
    menuColors() {
      return this.$vuetify.theme.dark
        ? "darkUnactive white--text"
        : "unActiveColor primaryColor";
    },
    //Toggle orderWindow
    async toggleOrderWindow(key) {
      var order = key ? key : this.orderType == "buy" ? "sell" : "buy";
      await this.$store.commit("ow/setOrderType", order);
      await this.quantityValidation();
      this.priceValidation("toggleOrderWindow");
      this.triggerPriceValiation();
      if (
        this.orderTypeList[this.orderTab] === "Bracket" &&
        !this.modifyOrder
      ) {
        this.targetPriceValidation();
        this.stopLossPriceValidation();
      }
      if (this.showMore) {
        this.discQtyValidation();
      }
    },

    //call orderWindow
    async hideOrderWindow() {
      !this.modifyOrder
        ? this.$store.commit("ow/setOrderWindow", false)
        : this.$store.commit("ow/setOrderWindowModify", false);
    },

    // order place
    async placeOrder() {
      this.isFormSubmit = true;
      let json = {
        qty: this.quantity,
        // complexity: this.getOrderTabValue,
        product: this.prodType.toUpperCase(),
        priceType: this.priceType,
        price: this.price,
        orderType: this.orderTypeList[this.orderTab],
        triggerPrice:
          this.triggerPrice && this.triggerPrice != 0 ? this.triggerPrice : "",
        stopLoss:
          this.stopLossPrice && this.stopLossPrice != 0
            ? this.stopLossPrice
            : "",
        trailingStopLoss:
          this.trailStopLossPrice && this.trailStopLossPrice != 0
            ? this.trailStopLossPrice
            : "",
        disclosedQty:
          this.disclosedQuantity && this.disclosedQuantity != 0
            ? this.disclosedQuantity
            : "",
        ret: this.validityType,
        targetPrice:
          this.targetPrice && this.targetPrice != 0 ? this.targetPrice : "",
        transType:
          this.$store.state.ow.orderType == "buy"
            ? "B"
            : this.$store.state.ow.orderType == "sell"
            ? "S"
            : this.$store.state.ow.orderType,
        ltp: this.price && this.price != 0 ? this.price : "",
        source: "WEB",
        mktProtection: "",
      };
      var qtyInputBox = document.querySelector("#qty");
      var priceInputBox = document.querySelector("#price");
      var trgPriceInputBox = document.querySelector("#triggerPrice");
      var targetPriceInputBox = document.querySelector("#targetPrice");
      var stopLossPriceInputBox = document.querySelector("#stopLossPrice");
      var trailStopLossPriceInputBox = document.querySelector(
        "#trailStopLossPrice"
      );
      var discQtyInputBox = document.querySelector("#discQty");
      this.quantityValidation();
      this.priceValidation("placeOrder");
      this.triggerPriceValiation();
      if (
        this.orderTypeList[this.orderTab] === "Bracket" &&
        !this.modifyOrder
      ) {
        this.targetPriceValidation();
        this.stopLossPriceValidation();
        // this.trailPriceValidation();   Trailing Stop Loss Block
      }
      if (this.showMore) {
        this.discQtyValidation();
      }
      if (
        this.orderTypeList[this.orderTab] == "Bracket"
          ? targetPriceInputBox.checkValidity() &&
            stopLossPriceInputBox.checkValidity()
          : // &&
          // trailStopLossPriceInputBox.checkValidity() //  Trailing Stop Loss Block
          true && qtyInputBox.checkValidity() && this.isPrice
          ? priceInputBox.checkValidity()
          : true && this.isTrgPrice
          ? trgPriceInputBox.checkValidity()
          : true && this.showMore
          ? discQtyInputBox.checkValidity()
          : true
      ) {
        this.requestData = json;
        this.callService();
      }
      this.isFormSubmit = false;
    },
    async callService() {
      !this.$store.state.stickyOrderCondition || this.modifyOrder
        ? this.hideOrderWindow()
        : "";

      await this.$store.dispatch("ow/placeOrder", this.requestData);
    },

    checkQuantity(event) {
      if (event.keyCode != 13) {
        this.showError = true;
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    decimalAllowed(event) {
      if (event.keyCode != 13) {
        this.showError = true;
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9.]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    // quantity validation
    quantityValidation() {
      var qtyVal = parseFloat(this.quantity);
      var qtyLot = this.floatSafeRemainder(qtyVal, this.minlot);
      const qtyInputBox = document.querySelector("#qty");
      if (qtyInputBox) {
        if (this.quantity.toString() == "") {
          return qtyInputBox.setCustomValidity("Quantity cannot not be empty");
        } else if (qtyVal == 0) {
          return qtyInputBox.setCustomValidity("Quantity cannot be zero");
        } else if (qtyLot != 0 && this.minlot > 0) {
          return qtyInputBox.setCustomValidity(
            "Quantity should be multiple of lot size. (" + this.minlot + ")"
          );
        } else {
          qtyInputBox.setCustomValidity("");
        }
      }
    },

    // price validation
    priceValidation(key) {
      const priceInputBox = document.querySelector("#price");

      var priceVal = parseFloat(this.price);

      var priceTick = this.floatSafeRemainder(priceVal, this.tickPrice);

      if (priceInputBox && this.isPrice) {
        if (this.price.toString() == "") {
          priceInputBox.setCustomValidity("Price cannot be Empty");
          return;
        } else if (priceVal == 0) {
          priceInputBox?.setCustomValidity(`Price cannot be zero`);
          this.isFormSubmit ? priceInputBox.reportValidity() : "";
          return;
        } else if (priceTick != 0) {
          priceInputBox?.setCustomValidity(
            `Price Should be multiple of Tick size ${this.tickPrice}`
          );
          priceInputBox?.reportValidity();
          return;
        } else {
          priceInputBox?.setCustomValidity("");
        }
      } else {
        priceInputBox.setCustomValidity("");
      }
    },

    // stoploss price validation
    triggerPriceValiation() {
      const trgPriceInputBox = document.querySelector("#triggerPrice");
      var trigPriceVal = parseFloat(this.triggerPrice);
      var priceVal = parseFloat(this.price);
      var trigTick = this.floatSafeRemainder(trigPriceVal, this.tickPrice);
      if (trgPriceInputBox && this.isTrgPrice) {
        if (this.triggerPrice.toString() == "") {
          trgPriceInputBox?.setCustomValidity("Trigger Price cannot be Empty");
          trgPriceInputBox?.reportValidity();
          return;
        } else if (trigPriceVal == 0) {
          trgPriceInputBox?.setCustomValidity("Trigger Price cannot be zero");
          trgPriceInputBox?.reportValidity();
          return;
        } else if (trigTick != 0) {
          trgPriceInputBox?.setCustomValidity(
            `Trigger Price Should be multiple of Ticks ${this.tickPrice}`
          );
          trgPriceInputBox?.reportValidity();
          return;
        } else if (this.orderType == "buy" && trigPriceVal > priceVal) {
          trgPriceInputBox?.setCustomValidity(
            `Trigger Price can not be greater than price (${parseFloat(
              priceVal
            ).toFixed(2)})`
          );
        } else if (this.orderType == "sell" && trigPriceVal < priceVal) {
          trgPriceInputBox?.setCustomValidity(
            `Trigger Price can not be less than price (${parseFloat(
              priceVal
            ).toFixed(2)})`
          );
        } else {
          trgPriceInputBox?.setCustomValidity("");
          trgPriceInputBox?.reportValidity();
        }
      } else {
        trgPriceInputBox?.setCustomValidity("");
        trgPriceInputBox?.reportValidity();
      }
    },

    //targetPrice based on Exchange
    targetPriceValidation() {
      const targetPriceInputBox = document.querySelector("#targetPrice");
      var value = parseFloat(this.targetPrice);
      var targetTick = this.floatSafeRemainder(value, this.tickPrice);
      if (targetPriceInputBox) {
        if (this.targetPrice.toString() == "") {
          targetPriceInputBox.setCustomValidity("Target Price Cannot be Empty");
          targetPriceInputBox?.reportValidity();
          return;
        } else if (value == 0) {
          targetPriceInputBox.setCustomValidity(
            "Target Price should be greater than 0"
          );
          this.isFormSubmit ? targetPriceInputBox.reportValidity() : "";
          return;
        } else if (targetTick != 0) {
          targetPriceInputBox.setCustomValidity(
            `Target Price should be multiple of " + ${this.tickPrice}`
          );
          targetPriceInputBox?.reportValidity();
          return;
        } else {
          targetPriceInputBox.setCustomValidity("");
          targetPriceInputBox?.reportValidity();
          return;
        }
      }
    },

    //stopLossPrice based on Exchange
    stopLossPriceValidation() {
      const stopLossPriceInputBox = document.querySelector("#stopLossPrice");
      var value = parseFloat(this.stopLossPrice);
      var slTick = this.floatSafeRemainder(value, this.tickPrice);
      if (stopLossPriceInputBox) {
        if (this.stopLossPrice.toString() == "") {
          stopLossPriceInputBox.setCustomValidity(
            "Stoploss Price Cannot be Empty"
          );
          stopLossPriceInputBox?.reportValidity();
          return;
        } else if (value <= 0) {
          stopLossPriceInputBox.setCustomValidity(
            "Stoploss Price Cannot be less then 0"
          );
          this.isFormSubmit ? stopLossPriceInputBox.reportValidity() : "";
          return;
        } else if (slTick != 0 && slTick != "0") {
          stopLossPriceInputBox.setCustomValidity(
            `Stoploss Price Should be multiple of " + ${this.tickPrice}`
          );
          stopLossPriceInputBox?.reportValidity();
          return;
        } else {
          return stopLossPriceInputBox.setCustomValidity("");
        }
      }
    },

    //trailPrice based on Exchange
    trailPriceValidation() {
      const trailStopLossPriceInputBox = document.querySelector(
        "#trailStopLossPrice"
      );
      var value = parseFloat(this.trailStopLossPrice);
      var trailTick = this.floatSafeRemainder(
        this.trailStopLossPrice,
        this.tickPrice
      );
      var targetVal = parseFloat(this.targetPrice);
      if (trailStopLossPriceInputBox) {
        if (this.trailStopLossPrice.toString() == "") {
          trailStopLossPriceInputBox.setCustomValidity(
            "TrailStoploss Price Cannot be Empty"
          );
          return;
        } else if (trailTick != 0) {
          trailStopLossPriceInputBox.setCustomValidity(
            `TrailStoploss Should be multiple of " + (${this.tickPrice})`
          );
          return;
        } else if (
          this.priceType == "L" &&
          value < this.tickPrice &&
          value != 0
        ) {
          trailStopLossPriceInputBox.setCustomValidity(
            `Trailing Stoploss should be minimum of TickPrice`
          );
          return;
        } else if (targetVal < value && value != 0) {
          trailStopLossPriceInputBox.setCustomValidity(
            `TrailStoploss Should be Less than " + (${targetVal})`
          );
          this.isFormSubmit ? trailStopLossPriceInputBox.reportValidity() : "";
          return;
        } else {
          trailStopLossPriceInputBox.setCustomValidity("");
        }
      }
    },

    //Disclosed qty based on Exchange
    discQtyValidation() {
      const discQtyInputBox = document.querySelector("#discQty");
      let dicPercentage;
      var disclosedQty = parseFloat(this.disclosedQuantity);
      var qty = parseFloat(this.currentQty);
      if (this.currentExchange == "MCX") {
        dicPercentage = 25 / this.Qty;
      } else {
        dicPercentage = 10 / this.Qty;
      }
      var disCheck = parseFloat(disclosedQty / qty);
      this.discQtytik = Math.ceil(disCheck);
      if (discQtyInputBox && this.isDisclosedQty) {
        /** Disclosed quantity */
        if (this.currentExchange != "NFO" && this.currentExchange != "BFO") {
          if (this.disclosedQuantity.toString() == "") {
            discQtyInputBox.setCustomValidity("Disc.Qty should not be empty");
            return;
          } else if (disclosedQty < this.discQtytik && disclosedQty != 0) {
            if (this.currentExchange == "MCX") {
              discQtyInputBox.setCustomValidity(
                "Disc.Qty should be either 0 or 25% to 100% of quantity"
              );
              return;
            } else if (this.currentExchange != "MCX") {
              discQtyInputBox.setCustomValidity(
                "Disc.Qty should be either 0 or 10% to 100% of quantity"
              );
              return;
            } else {
              discQtyInputBox.setCustomValidity("");
            }
          } else if (disclosedQty > qty) {
            discQtyInputBox.setCustomValidity(
              "Disc.Qty cannot be greater than Qty"
            );
            return;
          } else {
            discQtyInputBox.setCustomValidity("");
          }
        }
        discQtyInputBox.reportValidity();
      }
    },

    //Float Safe Remainder of last 2 digits or 4 digits
    floatSafeRemainder(val, step) {
      val = val?.toString();
      step = step?.toString();
      var valDecCount = val.includes(".")
        ? val.split(".")[1].length != null
          ? val.split(".")[1].length
          : 0
        : val.length;
      var stepDecCount = step.includes(".")
        ? step.split(".")[1].length != null
          ? step.split(".")[1].length
          : 0
        : step.length;
      var decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      var valInt = parseFloat(
        parseFloat(val).toFixed(decCount).toString().replaceAll(".", "")
      );
      var stepInt = parseFloat(
        parseFloat(step).toFixed(decCount).toString().replaceAll(".", "")
      );
      return (valInt % stepInt) / Math.pow(10, decCount);
    },

    // capitalize first letter
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    nseBseSwitch(exch) {
      this.$store.dispatch("ow/activeScrip", exch);
      this.priceValidation("nseBseSwitch");
    },
    async keyboardLisener(event) {
      if (
        event.target.id == "toggleElament" &&
        (Number(event.keyCode) == 37 || Number(event.keyCode) == 39)
      ) {
        var order = Number(event.keyCode) == 37 ? "sell" : "buy";
        if (this.orderType != order) {
          await this.toggleOrderWindow(order);
        }
      }
    },

    navigateBeta() {
      localStorage.clear();
    },
  },

  watch: {
    priceType: function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue,
            orderType: this.priceType,
          })
        );
      }
      if (val == "L") {
        this.price ? this.priceValidation("priceType") : "";
      }
      if (val == "SL") {
        this.price ? this.priceValidation("priceType") : "";
        this.triggerPrice ? this.triggerPriceValiation() : "";
      }
      if (val == "SL-M") {
        this.triggerPrice ? this.triggerPriceValiation() : "";
      }
    },

    prodType: function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue,
            orderType: this.priceType,
          })
        );
      }
    },

    orderTab: function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue,
            orderType: this.priceType,
          })
        );
      }
    },

    validityType: function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue,
            orderType: this.priceType,
          })
        );
      }
    },
  },

  created() {
    if (
      localStorage.getItem("orderPreference") == null ||
      localStorage.getItem("orderPreference") == undefined
    ) {
      localStorage.setItem(
        "orderPreference",
        JSON.stringify({
          product: this.prodType,
          validity: this.validityType,
          variety: this.getOrderTabValue,
          orderType: this.priceType,
        })
      );
    }
    window.orderWindow = this.listenMwTicks;
  },
};
